import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  // Original Code Starts From Here
  progress: 0,
  isFormFillStarted: false,
  websiteMetaData: {},
  firstName: "",
  lastName: "",
  zip: "",
  state: "",
  city: "",
  gender: "",
  medicare: "",
  zipCodeSetByMaxMind: true,
  homeState:"",
  homeCity: "",
  addressPostalCode: "",
  streetAddress: "",
  email: ""
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    startFromFill:(state, action)=>{
        state.isFormFillStarted = true
    },
    moveProgress:(state, action) =>{
        state.progress = action.payload
    },
    addWebsiteMetaData:(state, action)=>{
        state.websiteMetaData = window.websiteMetaData
    },
    setZipCode:(state, action)=>{
      state.zip = action.payload.zip;
      state.state = action.payload.state
      state.city = action.payload.city
    },
    setMedicare:(state, action)=>{
      state.medicare = action.payload
    },
    setName:(state, action)=>{
      state.firstName = action.payload.firstName
      state.lastName = action.payload.lastName
    },
    setLocalZipCode:(state, action)=>{
      state.zipCodeSetByMaxMind = action.payload
    },
    setAddress:(state, action)=>{
      state.streetAddress = action.payload.streetAddress;
      state.addressPostalCode = action.payload.addressPostalCode;
      state.homeCity = action.payload.homeCity;
      state.homeState = action.payload.homeState;
    },
    setEmail: (state, action)=>{
      state.email = action.payload
    },
    setPhoneNumber: (state, action)=>{
      state.phoneNumber = action.payload
    }
  },
})

export const {setPhoneNumber, setEmail, setAddress, setLocalZipCode, setName, setMedicare, startFromFill,setZipCode, moveProgress, addWebsiteMetaData } = formSlice.actions
export default formSlice.reducer