import React, { useEffect, useState } from "react";
import Logo from "../../assets/image/main_logo.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useSelector } from "react-redux";

const Footer = () => {
  const [html, setHtml] = useState();
  const websiteConfig = useSelector((s) => s.form?.websiteMetaData);

  const replaceShortCode = (html) => {
    if (html) {
      let str = html.replaceAll(
        "{{websiteName}}",
        websiteConfig.domainName
          ? websiteConfig.domainName.charAt(0).toUpperCase() +
              websiteConfig.domainName.slice(1)
          : ""
      );
      return str;
    }
  };

  useEffect(() => {
    setHtml(replaceShortCode(websiteConfig.disclaimer));
  }, [websiteConfig]);

  if (!html) return <></>;
  return (
    <div className="section_holder">
      <footer>
        <div className="container-xxl container">
          <div className="row first-row">
            <div className="col-12 col-md-12 col-lg-4">
              <div className="footer-logo">
                <a href="/">
                  <img src={Logo} alt="" />
                  <p className="footer-logo-text mt-0">
                    {websiteConfig.websiteName}
                  </p>
                </a>
                <p>©2023 {websiteConfig.domainName} All Right Reserved.</p>
              </div>
            </div>
            <div className="col-12 col-md-12 offset-lg-2 col-lg-6">
              <div className="footer_nav">
                <ul>
                  <li>
                    <Link
                      to={ROUTES.termsCondition}
                      target="_blank"
                      className="a-text-color"
                    >
                      Terms &amp; Condition
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={ROUTES.privacyPolicy}
                      target="_blank"
                      className="a-text-color"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={ROUTES.doNotSellMyPolicy}
                      target="_blank"
                      className="a-text-color"
                    >
                      Donot Sell My Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row last_row">
            <div className="col-12">
              <div
                className="disclaimer"
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
