import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";



const Congratulations = () => {
  const form = useSelector((s) => s.form);

  const navigate = useNavigate()

  useEffect(()=>{
    window.startLoader();

    const timeout = setTimeout(()=>{  
      navigate(ROUTES.thanks)
    }, 11000)

    if(sessionStorage.getItem('isReloaded') === 'no' || sessionStorage.getItem('isReloaded') === null){
      window.location.reload();
      sessionStorage.setItem('isReloaded', 'yes')
    }

    return ()=> clearTimeout(timeout)
  },[])
  return (
    <div className="form_holder">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="form_container">
              <div className="loader" id="loader">
                <div className="row">
                  <div className="col-12">
                    <h3>
                      Congratulations <span id="loadingnames"></span>, We've
                      found plans in your area!
                    </h3>
                  </div>
                  <div className="col-12">
									<div className="sections namewrapper">
										<div className="name">
											<p><i className="flaticon-2-profile-user">{form.firstName} {form.lastName}</i><span id="loadingname"></span>| <span id="gender">{form.gender}</span> | Born On <span id="bornon"></span> <i className="flaticon-loading nameloading"></i></p>
										</div>
									</div>
									<div className="sections statewrapper">
										<div className="statename">
											<p><i className="flaticon-2-location"></i><span id="cityname">{form.city}</span>, <span id="statename">{form.state}</span> <i className="flaticon-loading statenameloading"></i></p>
										</div>
									</div>
									<div className="sections companieswrapper">
										<div className="insurance-companies">
											<p><i className="flaticon-2-magnifying-glass"></i>Aetna | Blue Cross | Humana | United | Other.. <i className="flaticon-loading companieswrapperloading"></i></p>
										</div>
									</div>
									<div className="sections planwrapper">
										<div className="plans">
											<p><i className="flaticon-2-target"></i>New Medicare 2022 Plans <i className="flaticon-loading plansloading"></i></p>
										</div>
									</div>
									<div className="sections connectingwrapper">
										<div className="connecting">
											<p><i className="flaticon-2-agent"></i>Connecting you with a Licensed Insurance Agent <i className="flaticon-loading loading"></i></p>
										</div>
									</div>
								</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
