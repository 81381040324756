import React from "react";
import { useSelector } from "react-redux";

const PrivacyAndPolicy = () => {
  const { websiteName } = useSelector((s) => s.form.websiteMetaData);
  return (
    <section className="section1 terms_privacy_container">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12">
            <h2>Privacy Notice</h2>
            <hr />
            <h5 className="text-center bold mb-5">
              {websiteName} Privacy Notice
            </h5>
            <i>Last Updated: January 27, 2020</i>
            <p>
              {websiteName} owns and operates each website that displays this
              Privacy Notice (each, a "Site").
            </p>
            <p>
              Your privacy is important to us. We created this Privacy Notice to
              explain how we collect, use, and share the information that we
              collect from and about you.
            </p>
            <div className="d-none">
              <a href="#DataCollected">
                <span className="text-underline">Data Collected</span>
              </a>
              <br />
              <a href="#DataUse">
                <span className="text-underline">Data Use</span>
              </a>
              <br />
              <a href="#DataSharing">
                <span className="text-underline">Data Sharing</span>
              </a>
              <br />
              <a href="#OtherWebsites">
                <span className="text-underline">Other Websites</span>
              </a>
              <br />
              <a href="#Security">
                <span className="text-underline">Security</span>
              </a>
              <br />
              <a href="#Children">
                <span className="text-underline">Children</span>
              </a>
              <br />
              <a href="#ChoicesandAccess">
                <span className="text-underline">Choices and Access</span>
              </a>
              <br />
              <a href="#RetentionPeriod">
                <span className="text-underline">Retention Period</span>
              </a>
              <br />
              <a href="#InformationforCaliforniaConsumers">
                <span className="text-underline">
                  Information for California Consumers
                </span>
              </a>
              <br />
              <a href="#InformationforEuropeanConsumers">
                <span className="text-underline">
                  Information for European Consumers
                </span>
              </a>
              <br />
              <a href="#ChangestoThisPrivacyNotice">
                <span className="text-underline">
                  Changes to This Privacy Notice
                </span>
              </a>
              <br />
              <a href="#InsuranceMatchExpertsBusinesses">
                <span className="text-underline">{websiteName} Businesses</span>
              </a>
              <br />
              <a href="#DisputeResolution">
                <span className="text-underline">Dispute Resolution</span>
              </a>
              <br />
              <a href="#ContactUs">
                <span className="text-underline">Contact Us</span>
              </a>
            </div>

            <p id="DataCollected" className="pt-5 bold underline">
              Data Collected
            </p>
            <p>
              Data You Provide: We collect data that you provide to us when you
              interact with the Site or sites owned by third-parties with whom
              we have contractual relationships, including sites you visit prior
              to coming to this Site ("Media Partners"). In most cases when you
              come to the Site we will automatically gather or receive technical
              data about your prior journey, device and how you interact with
              our Site ("Technical Data"). Technical Data may include browser
              type, referrer URL, date and time of your visit, IP address, MAC
              address, device type, screen resolution, and OS version. Technical
              Data also includes "Cookie and Similar Data" described below.
            </p>
            <p>
              We gather additional data if you elect to engage with the Site or
              certain sites owned by our Media Partners. This includes contact
              information you elect to provide (e.g., name, address, email
              address, telephone number) about yourself or third parties to us
              and our Media Partners. (For example, if you elect to click on one
              of our advertisements on a Media Partner’s site that Media Partner
              may pass your contact information to us). We refer to the
              information you elect to provide as "
              <strong>Provided Attributes</strong>." In addition to contact
              information, Provided Attributes include age, gender, occupation,
              and education level. The Provided Attributes we collect are based
              upon the product and services you are inquiring about.
            </p>
            <p>
              Third Party Data: In some cases, we may augment the Technical Data
              and any Provided Attributes with Third Party Data. "
              <strong>Third Party Data</strong>" includes information from third
              parties such as credit reporting agencies. (We will only obtain
              credit reporting information with your consent, and will not
              retain any Social Security number you provide). Third Party Data
              may also include information intended to validate the data you
              provide (e.g., phone number, Vehicle Identification Number,
              outstanding loan amount). Finally, Third Party Data may include
              instances where you authorize us to use social media account
              information to log into or connect to this Site. We may combine
              the Technical Data, Provided Attributes and Third Party Data.
            </p>
            <p>
              Cookie and Similar Data: When you use this Site, we may recognize
              you or your device through use of a "cookie" or other tracking
              technology. These technologies act as unique identifiers and allow
              us to tailor this Site to your specified interests and track your
              activity on this Site. This information may be added to your user
              profile and used to deliver offers and advertisements that we
              believe are relevant to your interests.
            </p>
            <p>
              We have contractual relationships with advertisers, advertising
              agencies and other intermediaries that have advertisers as clients
              (collectively, "<strong>Advertising Partners</strong>"). Our Media
              Partners and Advertising Partners (collectively, "
              <strong>Partners</strong>") may also utilize third-party cookies
              and other tracking technologies like clear pixel GIFs (i.e., web
              beacons) to identify you. We and our Partners may use these
              technologies to measure responses to emails and page views on this
              Site and our Partners’ websites. These technologies are used to
              enhance this Site, to make this Site more relevant to you, and to
              measure the effectiveness of our email and other advertising
              campaigns and those of our Partners. You can opt out of some of
              our and our Partners’ use of cookies and other technologies at:{" "}
              <a href="https://www.google.com/settings/ads">Google</a>,{" "}
              <a href="http://networkadvertising.org/optout_nonppii.asp">
                the Network Advertising Initiative
              </a>
              ,{" "}
              <a href="http://assets.invitemedia.com/opt-out.html">
                Invite Media
              </a>
              , and{" "}
              <a href="http://www.clicktale.net/disable.html">Clicktale</a>.
            </p>
            <p>
              Tracking Options and California Do Not Track Disclosures: Certain
              Sites require cookies to function efficiently. We may use cookies
              to deliver advertising we believe is relevant to you and to link
              data collected across other devices you use. You can adjust your
              setting to limit tracking or decline cookies, but, as a result,
              you may not be able to use certain features of this Site or take
              full advantage of all of our offerings. Please refer to your
              device or browser settings for more information on how to delete
              or decline cookies and control your tracking preferences. This
              Site does not respond to Do Not Track signals from most browsers.
            </p>

            <p id="DataUse" className="pt-5 bold underline">
              Data Use
            </p>
            <p>
              We use the data we collect from and about you through this Site
              primarily to connect you with Advertising Partners who can provide
              you with information about the products and services you are
              seeking. We generally do this using Technical Data and Provided
              Attributes, but in certain cases (e.g. lending products) we may
              also use Third Party Data.
            </p>
            <p>
              For example, we may have an auto insurance Partner who is only
              licensed in certain states. We may use Technical Data or Provided
              Attributes to determine if you are a resident of one of those
              states. We refer to Advertising Partner requirements generally as
              "segmentation" and the alignment of consumer data and segments as
              "matching."
            </p>
            <p>
              We may also use the data we collect from and about you to (i)
              respond to your requests (e.g., receive white papers, email, phone
              calls or texts); (ii) to keep you informed of our products and
              services (and those of our Partners); (iii) to allow you to send
              message through this Site; (iv) to improve our Sites and business;
              (v) to fulfill any purchase you make; and (vi) to use your data in
              an aggregated, non-specific format for analytic and demographic
              purposes. We use Technical Data to monitor this Site’s
              performance, analyze traffic patterns and usage, ensure that this
              Site and any transactions function properly, and gather
              demographic information about our user base as a whole. We may use
              automated decision-making in segmentation and matching.
            </p>

            <p id="DataSharing" className="pt-5 bold underline">
              Data Sharing
            </p>
            <p>
              In addition to the primary Data Use described above (i.e., sharing
              your data with Advertising Partners and third parties for the
              purpose of providing you with information about the products and
              services you are seeking), we may share your data as follows: (i)
              with other {websiteName} businesses; (ii) with service providers
              who assist us in compliance and verification activities (e.g.,
              credit agencies), or provide services such as payment processing,
              IT, and data analysis; (iii) if you choose to send a message
              through, or contribute to public areas of, this Site (e.g.,
              message boards, blogs); and (iv) with a third party in the event
              of a sale or transfer of all or part of our business.
            </p>
            <p>
              We may also disclose the data we collect from and about you to (i)
              comply with legal processes; (ii) to respond to lawful requests
              from authorities (including public and government authorities
              outside your country of residence, and including to meet national
              security or law enforcement requirements); (iii) to enforce our
              Terms of Service and this Privacy Notice; (iv) to protect our
              business, rights, privacy, safety, or property or that of others;
              and (v) as necessary under applicable law (including laws outside
              your country of residence).
            </p>

            <p id="OtherWebsites" className="pt-5 bold underline">
              Other Websites
            </p>
            <p>
              This Site may contain links to websites that are not owned and
              operated by us. We are not responsible for the content or
              practices of such websites, including their practices with respect
              to your data. We have no responsibility or liability for such
              practices, and such practices govern your use of those websites.
              If this Site includes any mapping features, those mapping features
              are powered by Google, Inc. and governed by Google’s{" "}
              <a href="http://www.google.com/privacy.html">privacy policy</a>,
              as amended from time to time.
            </p>

            <p id="OtherWebsites" className="pt-5 bold underline">
              Other Websites
            </p>
            <p>
              We have taken certain physical, technical, and administrative
              steps to protect the data we collect form and about Site users. We
              use commercially reasonable efforts to encrypt sensitive data such
              as credit card number and personal health data. We use certain
              reasonable security measures to help protect your personal
              information. However, no electronic data transmission or storage
              of information can be guaranteed to be 100% secure. Please note
              that we cannot ensure or warrant the security of any information
              you transmit to us. You use the Site and provide us with your data
              at your own risk. If we share data as detailed in this Privacy
              Notice with a third party we will use all reasonable efforts to
              ensure that they keep the information secure and only use the
              information consistent with the terms of this Privacy Notice.
            </p>

            <p id="Security" className="pt-5 bold underline">
              Security
            </p>
            <p>
              We have taken certain physical, technical, and administrative
              steps to protect the data we collect form and about Site users. We
              use commercially reasonable efforts to encrypt sensitive data such
              as credit card number and personal health data. We use certain
              reasonable security measures to help protect your personal
              information. However, no electronic data transmission or storage
              of information can be guaranteed to be 100% secure. Please note
              that we cannot ensure or warrant the security of any information
              you transmit to us. You use the Site and provide us with your data
              at your own risk. If we share data as detailed in this Privacy
              Notice with a third party we will use all reasonable efforts to
              ensure that they keep the information secure and only use the
              information consistent with the terms of this Privacy Notice.
            </p>

            <p id="Children" className="pt-5 bold underline">
              Children
            </p>
            <p>
              We do not knowingly collect, use, or share the personal data of
              children under the age of 13 and this Site is not directed to
              individuals under 13. If we are made aware that we have collected
              personal data from an individual under age 13 we will delete this
              information as soon as possible.
            </p>

            <p id="RetentionPeriod" className="pt-5 bold underline">
              Retention Period
            </p>
            <p>
              In general, we will retain your data for as long as is needed to
              connect you with Advertising Partners who can provide you with
              information about the products and services you are seeking, which
              is typically 30-180 days. We will typically retain an archived
              copy of your data for six years as required or permitted by law
              for legal and compliance purposes.
            </p>

            <p
              id="InformationforCaliforniaConsumers"
              className="pt-5 bold underline"
            >
              Information for California Consumers
            </p>
            <p>
              The California Consumer Privacy Act ("<strong>CCPA</strong>")
              affords certain rights and protections to California residents.
              This section is intended to further describe (i) the categories
              and sources of data that {websiteName} collects, sells or shares;
              (ii) the purpose for collection; (iii) the types of third parties
              to whom {websiteName} discloses that information; and (iv) details
              about how California residents can exercise their rights under the
              CCPA.
            </p>
            <p>
              The California Consumer Privacy Act ("<strong>CCPA</strong>")
              affords certain rights and protections to California residents.
              This section is intended to further describe (i) the categories
              and sources of data that {websiteName} collects, sells or shares;
              (ii) the purpose for collection; (iii) the types of third parties
              to whom {websiteName} discloses that information; and (iv) details
              about how California residents can exercise their rights under the
              CCPA.
            </p>
            <p>
              <span className="text-underline">
                Categories of Data {websiteName} Collects
              </span>
              . {websiteName} collects the following CCPA categories of data:
              (i) identifiers, such as a name, email address, telephone number,
              postal address, unique personal identifier, online identifier, IP
              address, account name, Social Security number, driver’s license
              number; (ii) personal identifiers under Cal. Civ. Code §
              1798.80(e), such as insurance policy number, education, employment
              status, bank account information, credit card or debit card
              number, financial information, medical information, health
              insurance information; (iii) protected classification
              characteristics under California law, such as age, marital status,
              medical condition, gender, veteran or military status; (iv)
              commercial information, such as products or services obtained, or
              considered, or other purchasing or consuming histories or
              tendencies; (v) internet activity, such as browsing history and
              search history information; (vi) geolocation data; (vii)
              professional or employment information, such as consumer-reported
              current or past job history; (viii) inferences drawn from some of
              the information above (e.g., email open rates and time).
            </p>
            <p>
              {websiteName} generally collects the above data as Technical Data
              or Provided Attributes. For example, {websiteName} does not create
              user profiles to determine the gender, marital status or
              employment status of consumers. {websiteName} receives each of
              those categories of data only as self-reported data from the
              consumer.
            </p>
            <p className="underline">Data Sources</p>
            <p>
              {websiteName}: {websiteName} collects Technical Data and Provided
              Attributes from consumers who land on or engage with our Sites,
              our call centers or other {websiteName} owned-properties (e.g., in
              response to emails from or on behalf of ({websiteName}).
            </p>
            <p>
              Partners and Third Parties: Consumers who visit websites owned by
              our Media Partners may be redirected to our Sites. In some cases,
              we may gather data directly from Media Partner websites, or Media
              Partners or other third parties may post data to our Sites or
              otherwise transfer data to us. This may occur via display ads,
              click listings, inquiry forms or telephonic transfers from Media
              Partner call centers. Our Advertising Partners may also provide us
              with data.
            </p>
            <p>
              Service Providers: {websiteName} engages service providers to
              perform a variety of functions (e.g., verifying consumer consent,
              validating email addresses or phone numbers).
            </p>
            <p>
              Authorization: We require all parties from whom we receive data,
              whether they are Partners, Service Providers or other third
              parties, to represent and warrant to us that they have
              appropriately obtained the data provided to us and have the
              authority to share the data with us.
            </p>
            <p className="underline">Purpose for Data Collection</p>
            <p>
              As described above in "
              <strong>
                <a href="#DataUse">Data Use</a>
              </strong>
              ," {websiteName} primarily uses the data it collects to connect
              consumers with Advertising Partners who can provide information
              about the products and services those consumers are seeking.
            </p>
            <p>
              <span className="underline">
                Categories of Third Parties with Whom {websiteName} Discloses
                Personal Data.
              </span>{" "}
              In addition to the sharing for the primary use described in "
              <strong>
                <a href="#DataSharing">Data Sharing</a>
              </strong>
              ," we may share your data with other {websiteName} businesses,
              service providers, and third party websites.
            </p>
            <p>
              <span className="text-underline">Categories of Data Sold.</span>{" "}
              As described above in "
              <strong>
                <a href="#DataUse">Data Use</a>
              </strong>
              " and "
              <strong>
                <a href="#DataSharing">Data Sharing</a>
              </strong>
              ," {websiteName} may transfer Technical Data, Provided Attributes
              and Third Party Data to Advertising Partners who can provide the
              consumer with information about the products and services they are
              seeking. We may receive a fee for the transfer of that data or
              when the consumer purchases a product or service.
            </p>
            <p>
              <span className="text-underline">CCPA Rights.</span> The CCPA
              affords certain rights and protections to California residents
              including: (i) the right to request that we delete any "personal
              information" that we have collected from you ("
              <strong>Right to Delete</strong>"); (ii) the right to request that
              we disclose the categories and/or specific pieces of personal
              information collected and sold ("<strong>Right to Know</strong>");
              and (iii) the right to direct us not to sell their "personal
              information" ("<strong>Right to Opt-Out</strong>"). "
              <strong>Personal information</strong>" is defined under the CCPA
              as "information that identifies, relates to, describes, is
              reasonably capable of being associated with, or could reasonably
              be linked, directly or indirectly, with a particular consumer or
              household."
            </p>
            <p>
              California residents who would like to submit a Request to Know,
              Delete, or Opt-Out should follow the links below. You may also
              submit a Request to Know by calling our toll-free number
              (844)-872-7854. Upon submission, you will be asked to verify your
              email address. If we are able to verify your Request to Know or
              Delete, (e.g., that you are a California resident and that we have
              or have sold, as the case may be, your "personal information"), we
              will respond within 45 days of your submission (or such additional
              time period as the law allows us).
            </p>
            <p
              id="InformationforEuropeanConsumers"
              className="pt-5 bold underline"
            >
              Information for European Consumers
            </p>
            <p>
              Our Site and business are operated in the United States. If you
              are located outside of the United States any data you provide will
              be transferred to and processed in the United States. For
              residents of the European Union (and the United Kingdom), {websiteName}, Inc., is the data controller for your data collected
              under this Privacy Notice. We process your data pursuant to your
              consent, where legally required to do so, or where we have a
              legitimate interest, such as using your data for fraud prevention,
              analytics or to improve our Sites and business, and for our
              marketing purposes.
            </p>
            <p>
              In addition, you have the following rights with respect to the
              data collected from and about you on this Site: (i) the right to
              request access to such data; (ii) the right to request the
              correction, erasure, and restriction of processing of such data;
              (iii) in some instances, the right to object to the processing of
              such data; (iv) in some instances, the right to request that 
              {' ' + websiteName} export, in a structured, commonly used, and machine
              readable format, and transmit to another controller, such personal
              data; (v) the right to withdraw, at any time, any consent to the
              processing of such personal data.
            </p>
            <p>
              To exercise these rights please use the "Contact Us" mechanism
              provided below.
            </p>

            <p id="ChangestoThisPrivacyNotice" className="mt-5 bold">
              Changes to this Privacy Notice
            </p>
            <p>
              We may amend this Privacy Notice from time to time. When we do, we
              will post an updated version of the Privacy Notice and revise the
              "<strong>LAST UPDATED</strong>" date at the top. We will provide
              appropriate notice to you if we change the Privacy Notice in any
              material way. Using this Site after any changes are made means you
              accept those changes and the revised Privacy Notice.
            </p>

            <p id="InsuranceMatchExpertsBusinesses" className="mt-5 bold">
              {websiteName} Businesses
            </p>
            <p>
              For purposes of this Privacy Notice, "<strong>you</strong>" or "
              <strong>your</strong>" means the person(s) using this Site or the
              products or services offered through this Site. "
              <strong>{websiteName}</strong>," "<strong>us</strong>," or "
              <strong>we</strong>" includes (but is not limited to) {websiteName}, Inc. is the party responsible for this Privacy Notice
              and compliance with it.
            </p>
            <p id="DisputeResolution" className="mt-5 bold">
              Dispute Resolution
            </p>
            <p>
              You have the right to submit feedback about this Privacy Notice,
              or the practices described in it, to the applicable supervisory
              authority in your jurisdiction of residence. For U.S. residents
              this includes the Federal Trade Commission and your state Attorney
              General. For Non-U.S. residents this includes the Data Protection
              Authority in your country of residence.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyAndPolicy;
