import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { moveProgress, setName } from "../../store/form";

const initialValues = {
  firstName: '',
  lastName: '',
};

const FullName = () => {
  const dispatch = useDispatch();

  const form = useSelector(s=> s.form);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    console.log(values); // You can perform actions with the form values here
  };

  // const validationSchema = Yup.object().shape({
  //   firstName: Yup.string().required('Required'),
  //   lastName: Yup.string().required('Required'),
  // });
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: (values) => {
      dispatch(setName({ firstName: values.firstName, lastName: values.lastName }))
      navigate(ROUTES.address);
      // Perform form submission logic here
    },
  });

  useEffect(() => {
    dispatch(moveProgress(33));

    formik.setValues({ firstName: form.firstName, lastName: form.lastName })
  }, []);
  return (
    <div className='form_holder'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12 col-md-12'>
            <div className='form_container'>
              <form onSubmit={(e)=> {
                e.preventDefault()
                formik.handleSubmit();
                return false
              }
                }>
                <div className='steps'>
                  <h3
                    id='steps-uid-0-h-0'
                    tabIndex='-1'
                    className='title current'
                  >
                    What Is Your Full Name?
                  </h3>
                  <section className='step-wrapper'>
                    <div className='form-step'>
                      <div className='name-wrapper'>
                        <div className='firstnameholder'>
                          <div className='input-group'>
                            <input
                              type='text'
                              className='custominput'
                              placeholder='First Name'
                              name='firstName'
                              id='email'
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {/* {formik.touched.firstName &&
                              formik.errors.firstName && (
                                <div className='error error-message mt-1 ms-3'>
                                  {formik.errors.firstName}
                                </div>
                              )} */}
                          </div>
                        </div>
                        <div className='lastnameholder mt-3 mt-sm-0' >
                          <div className='input-group'>
                            <input
                              type='text'
                              className='custominput'
                              placeholder='Last Name'
                              name='lastName'
                              id='lastName'
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                                {/* {formik.touched.lastName &&
                              formik.errors.lastName && (
                                <div className='error error-message mt-1 ms-3'>
                                  {formik.errors.lastName}
                                </div>
                              )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className='actions clearfix'>
                      <button className='continue-btn'>
                        Continue
                      </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullName;
