import React from 'react'
import Navbar from '../components/NavBar/Navbar'
import Footer from '../components/Footer/Footer'
import PrivacyAndPolicy from '../components/TermsAndPrivacy/PrivacyPolicy'

const PrivacyPolicy = () => {
  return (
    <div>
        <Navbar />
        <PrivacyAndPolicy />
        <Footer />
    </div>
  )
}

export default PrivacyPolicy
