import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { LEAD } from '../../constants/lead';
import { moveProgress, setPhoneNumber } from '../../store/form';

const initialValues = {
  phoneNumber: '',
};

const PhoneNumber = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useSelector((s)=> s.form)

  const handleSubmit = (values) => {
    console.log(values); // You can perform actions with the form values here
    dispatch(setPhoneNumber(formik.values.phoneNumber));
    removeLeadScript();
    navigate(ROUTES.congratulations);

  };


  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
      navigate()
    },
  });

  const removeLeadScript = () => {
    const leadNode = window.document.getElementById(LEAD.id);
    if (leadNode) leadNode.remove();
    const leadWrapper = window.document.getElementById(LEAD.wrapperId);
    if (leadWrapper) leadWrapper.remove();
  };
  
  useEffect(() => {
    dispatch(moveProgress(83));
    formik.setValues({ phoneNumber: form.phoneNumber });
    sessionStorage.setItem('isReloaded', String('no'));
  }, []);

  return (
    <div className='form_holder'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12 col-md-12'>
            <div className='form_container'>
              <form >
                <div className='steps'>
                  <h3
                    id='steps-uid-0-h-0'
                    tabIndex='-1'
                    className='title current'
                  >
                    Hello , We've Found Plans In Your Area!
                  </h3>
                  <section className='step-wrapper'>
                    <div className='form-step'>
                      <div className='input-group'>
                        <input
                          type='tel'
                          className='custominput telephone phone_us'
                          name='phoneNumber'
                          id='phoneNumber'
                          placeholder='Phone Number'
                          inputMode='text'
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber && (
                            <div className='error error-message mt-1 ms-1'>
                              {formik.errors.phoneNumber}
                            </div>
                          )} */}
                      </div>

                      <div className='input-button' onClick={handleSubmit}>
                        <a>
                          View My Quote
                        </a>
                      </div>
                      <p className='terms'>
                        <label htmlFor=''>
                          By pressing the "View My Quote" (1) I provide my
                          express written consent via electronic signature to
                          receive emails, telephone calls, text messages (SMS),
                          artificial or pre-recorded messages from Senior
                          Assistant its{' '}
                          <Link
                            to={ROUTES.ourPartner}
                            className='a-text-color'
                            target='_blank'
                          >
                            affiliates, and/or any third-party partners
                          </Link>{' '}
                          (or their service provider partners on their behalf)
                          regarding their products and services (Including
                          Medicare Advantage plans, Medicare Part D Prescription
                          Drug Plans or Medicare Supplement Insurance Plans,
                          Final Expense Plans) at the email address and
                          telephone number provided, including my wireless phone
                          number (if provided). utilizing an automated telephone
                          dialing system and I understand that I am not required
                          to grant this consent as a condition of purchasing and
                          property, goods or services from the foregoing
                          companies and my consent can be revoked at any time.
                          (2) I agree to this websites{' '}
                          <Link
                            to={ROUTES.privacyPolicy}
                            target='_blank'
                            className='a-text-color'
                          >
                            Privacy Policy
                          </Link>{' '}
                          and{' '}
                          <Link
                            to={ROUTES.termsCondition}
                            target='_blank'
                            className='a-text-color'
                          >
                            Terms of Use
                          </Link>
                          . (3) I understand that this is a solicitation for
                          insurance. Plans are insured or covered by a Medicare
                          Advantage organization with a Medicare contract and/or
                          a Medicare-approved Part D sponsor. Enrollment in the
                          plan depends on the plan’s contract renewal with
                          Medicare. We do not offer every plan available in your
                          area. Any information we provide is limited to those
                          plans we do offer in your area. Please contact
                          Medicare.gov or 1–800 MEDICARE to get information on
                          all of your options
                        </label>
                      </p>
                    </div>
                  </section>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
