import React, { useState, useEffect } from 'react';
import CallCenterAgentImage from '../../assets/image/call-center-agent-1.png';
const Thanks = () => {
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(30);
  useEffect(() => {
    const interval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        // Restart the countdown
        setMinutes(2);
        setSeconds(30);
      } else {
        if (seconds === 0) {
          setMinutes(prevMinutes => prevMinutes - 1);
          setSeconds(59);
        } else {
          setSeconds(prevSeconds => prevSeconds - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);
  return (
    <div className='thank-you'>
      <div className='thankyou'>
        <div className='container'>
          <div className='row justify-content-md-center'>
            <div className='col-md-8'>
              <div className='thankyoumessage'>
                <h1>
                  Hello, thank you for submitting your information! Please call
                  on the number mentioned below to connect with a licensed
                  insurance agent to confirm your eligibility.
                </h1>
                <div className='thankyoucallnumber'>
                  <div className='call-agent'>
                    <img src={CallCenterAgentImage} alt='' />
                  </div>
                  <p className='text-center due-to-para'>
                    <span>
                      Due to high call volume, your agent will remain available
                      for the next:
                    </span>{' '}
                    <strong>
                      <span id='call-timer'>
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                      <span> minutes</span>
                    </strong>
                    .
                  </p>
                  <div className='call-btn-big'>
                    <a href='tel:(844) 809-7942'>
                      <i className='flaticon-2-telephone'></i>
                      <small>1 (844) 809-7942</small>
                    </a>
                  </div>
                  <p className='text-center'>
                    <small>
                      Call now to speak with a licensed medicare consultant.
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
