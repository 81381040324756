import React from 'react';
import CompletedTaskImage from "../../assets/image/001-completed-task.svg"
import ImmigrationImage from "../../assets/image/002-immigration.svg"
import HumanResource from "../../assets/image/003-human-resources.svg"


const HowItWorks = () => {
  return (
    <div className='section_holder'>
      <section className='section2'>
        <div className='container-xxl container'>
          <div className='row'>
            <div className='col-12 offset-lg-2 col-lg-8'>
              <div className='section2title'>
                <h1>How it works</h1>
                <p>
                  In just 3 simple steps, you can be on your way to affordable
                  Medicare coverage.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-12 col-sm-12'>
              <div className='sectionfeature'>
                <div className='icon-box'>
                  <img src={CompletedTaskImage} alt='' />
                </div>
                <h2>Fill Out A Quick Form</h2>
                <p>
                  Simply fill our brief questionnaire or make a quick phone call
                  to determine the type coverage that meets your needs and
                  budget. It's no obligation free, quick, and easy. You can be
                  on your way in minutes!
                </p>
                <br />
              </div>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12'>
              <div className='sectionfeature'>
                <div className='icon-box'>
                  <img src={ImmigrationImage} alt='' />
                </div>
                <h2>Access Hundreds of Plans</h2>
                <p>
                  Call us to connect with a licensed insurance agent and discuss
                  all of the different plan options available to you. Compare
                  and choose the right medicare plan according to your coverage
                  needs and budget.
                </p>
                <br />
              </div>
            </div>
            <div className='col-lg-4 col-md-12 col-sm-12'>
              <div className='sectionfeature'>
                <div className='icon-box'>
                  <img src={HumanResource} alt='' />
                </div>
                <h2>Choose &amp; Get Covered</h2>
                <p>
                  Have the peace of mind that that you're getting the right plan
                  in your area for you, with the power to compare, choose, and
                  possibly save
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
