import React from "react";
import Logo from "../../assets/image/main_logo.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import "../../assets/css/index.scss";
import { useSelector } from "react-redux";

const Header = () => {
  const websiteConfig = useSelector((s) => s.form?.websiteMetaData);
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-5">
            <div className="logo">
              <Link to={ROUTES.zipCode}>
                <img src={Logo} alt="" />
                <p className="footer-logo-text mt-0">
                  {websiteConfig.websiteName}
                </p>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-7">
            <div className="number">
              <a className="agent" href="tel:+18773291650">
                <div className="header-flex">
                  <div className="header-flex1">
                    <div className="htext">
                      <p>Speak to a Licensed Insurance Agent</p>
                    </div>
                    <div className="hnumber">
                      <i className="flaticon-2-telephone me-2"></i>(844) 809-7942 TTY
                      711
                    </div>
                    <div className="htext2">
                      <span></span> Licensed Insurance Agents available now
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
