import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Congratulations from '../components/FormHolder/Congrachulation';
import '../assets/css/index.scss';

const CongratulationPage = () => {
  
  if(sessionStorage.getItem('isReloaded') === 'no' || sessionStorage.getItem('isReloaded') === null){
    window.location.reload();
    sessionStorage.setItem('isReloaded', 'yes')
  }

  return (
    <div className='site_wrapper'>
      <Header />
      <Congratulations />
      <Footer />
      <div className='section_holder'>
        <div className='d-block d-sm-block d-md-none d-lg-none'>
          <a href='tel:+18773291650' className='callbutton'>
            <i className='flaticon-2-telephone'></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CongratulationPage;
