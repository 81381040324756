import React from 'react'
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import FormHeadLine from '../components/FormHeadLine/FormHeadLine';
import '../assets/css/index.scss'
import { Outlet } from 'react-router-dom';

const Form = ({progressCounter}) => {
  return (
    <div className='site_wrapper'>
        <Header />
        <FormHeadLine  progressCounter={progressCounter}/>
        <Outlet />
        <Footer />
        <div className="section_holder">
        <div className="d-block d-sm-block d-md-none d-lg-none">
          <a href="tel:+18773291650" className="callbutton">
            <i className="flaticon-2-telephone"></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Form