import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { moveProgress, setEmail } from "../../store/form";

const initialValues = {
  email: "",
};

const Email = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useSelector((s) => s.form);

  const handleSubmit = (values) => {
    console.log(values); // You can perform actions with the form values here
  };

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string().required('Required'),
  // });
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: (values) => {
      dispatch(setEmail(values.email));
      navigate(ROUTES.phoneNumber);
      // Perform form submission logic here
    },
  });

  useEffect(() => {
    dispatch(moveProgress(67));
    formik.setValues({ email: form.email });
  }, []);
  return (
    <div className="form_holder">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="form_container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <div className="steps">
                  <h3
                    id="steps-uid-0-h-0"
                    tabIndex="-1"
                    className="title current"
                  >
                    Whats Is Your Email?
                  </h3>
                  <section className="step-wrapper">
                    <div className="form-step">
                      <div className="input-group">
                        <input
                          type="text"
                          className="custominput"
                          placeholder="Email"
                          name="email"
                          id="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.email &&
                            formik.errors.email && (
                              <div className='error error-message mt-1 ms-1'>
                                {formik.errors.email}
                              </div>
                            )} */}
                      </div>
                    </div>
                  </section>
                  <div className="actions clearfix">
                    <Link to={ROUTES.address}>
                      <button className="continue-btn">Back</button>
                    </Link>
                    <button className="continue-btn">Continue</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;
