import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Form from "./pages/Form";
import ZipCodeComponent from "./components/FormHolder/ZipCode";
import MedicarePart from "./components/FormHolder/MedicarePart";
import { ROUTES } from "./constants/routes";
import FullName from "./components/FormHolder/FullName";
import Address from "./components/FormHolder/Address";
import Email from "./components/FormHolder/Email";
import PhoneNumber from "./components/FormHolder/PhoneNumber";
import Congratulations from "./components/FormHolder/Congrachulation";
import ThanksPage from "./pages/Thanks";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DoNotSellMyPolicy from "./pages/DoNotSellMyPolicy";
import OurPartner from "./pages/OurPartner";
import { useDispatch, useSelector } from "react-redux";
import { addWebsiteMetaData } from "./store/form";
import CongratulationPage from "./pages/CongratulationPage";
import { HelmetProvider } from "react-helmet-async";

console.error = () =>{}
console.log = () =>{}
console.warn = () => {}

function App() {
  const websiteConfig = useSelector((s) => s.form.websiteMetaData);
  const [progressCounter, setProgressCounter] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = window.websiteMetaData.title
    dispatch(addWebsiteMetaData());
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path={ROUTES.landing} element={<Home />} />
            <Route
              path={ROUTES.formParent}
              element={<Form progressCounter={progressCounter} />}
            >
              <Route
                path={ROUTES.zipCode}
                element={
                  <ZipCodeComponent setProgressCounter={setProgressCounter} />
                }
              />
              <Route
                path={ROUTES.medicare}
                element={
                  <MedicarePart setProgressCounter={setProgressCounter} />
                }
              />
              <Route
                path={ROUTES.fullName}
                element={<FullName setProgressCounter={setProgressCounter} />}
              />
              <Route
                path={ROUTES.address}
                element={<Address setProgressCounter={setProgressCounter} />}
              />
              <Route
                path={ROUTES.email}
                element={<Email setProgressCounter={setProgressCounter} />}
              />
              <Route path={ROUTES.phoneNumber} element={<PhoneNumber />} />
            </Route>
            <Route
              path={ROUTES.congratulations}
              element={<CongratulationPage />}
            />
            <Route path={ROUTES.termsCondition} element={<TermsCondition />} />
            <Route path={ROUTES.thanks} element={<ThanksPage />} />
            <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />
            <Route
              path={ROUTES.doNotSellMyPolicy}
              element={<DoNotSellMyPolicy />}
            />
            <Route path={ROUTES.ourPartner} element={<OurPartner />} />
          </Route>
        </Routes>

        
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
