export const ROUTES = {
    landing: "/",
    formParent: '/form',
    zipCode: "/form/zip-code",
    medicare: '/form/medicare',
    fullName:"/form/full-name",
    address:"/form/address",
    email:"/form/email",
    phoneNumber:"/form/phone-number",
    congratulations:"/form/congratulations",
    thanks:"/form/thanks",
    termsCondition:"/terms-condition",
    privacyPolicy:"/privacy-policy",
    doNotSellMyPolicy:"/do-not-sell-my-policy",
    ourPartner:"/our-partner"
}