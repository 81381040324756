import React from 'react'
import OldGrannyImage from "../../assets/image/old-granny.jpg"

const InsuranceSection = () => {
  return (
    <div className='section_holder'>
      <section className="section3">
			<div className="container-xxl container">
				<div className="row sectionfirst">
					<div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-6 d-none d-sm-none d-md-block d-lg-block">
						<div className="section1image">
							<img src={OldGrannyImage} alt="" className="img-fluid" />
						</div>
					</div>
					<div className="col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-6">
						<div className="section1holder">
							<h1>What is <span>Medicare Insurance?</span></h1>
							<p>Medicare is a federal health insurance program that applies generally to people over 65, but can also include certain younger people with disabilities and/or people with End-Stage Renal Disease (ESRD). Medicare has 3 parts. Part A covers hospital insurance, Part B covers medical insurance and Part D covers prescriptions. Original Medicare is offered through the government and covers hospital and medical insurance. Original Medicare can either be obtained directly through the government through a trusted insurance carrier. If Medicare is obtained through an insurance provider this is referred to as an "Advantage" Plan as often times these insurance providers provide advantages such as customer service, ease of applying and flexible spending debit cards. Unfortunately, Medicare Advantage Plans do not cover complete medical costs and if there were an unfortunate medical circumstance you may be financially liable for the remaining costs. Medicare Supplement Plans also known as Medigap is a Medicare Supplement Insurance that helps&nbsp;fill "gaps" in Original Medicare and is sold by private companies.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
    </div>
  )
}

export default InsuranceSection