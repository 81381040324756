import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useSelector } from 'react-redux';

const MedicarePlan = () => {
  const form = useSelector((a)=> a.form)
  return (
    <div className='section_holder'>
      <section className='section5'>
        <div className='section5bg'></div>
        <div className='container-xxl container'>
          <div className='row'>
            <div className='offset-lg-0 col-lg-5 offset-md-2 col-md-8 col-sm-12'>
              <h1>Find The Medicare Advantage Plan That Fits Your Needs</h1>
              <p>Call now to speak to a helpful licensed insurance agent.</p>
              <div className='lastsectioncontent'>
                <div className='cta'>
                  <form
                    className='ctaform'
                    method='post'
                  />
                  <label htmlFor='footerzip'>Start By Adding Your ZIP Code</label>
                  <div className='formgroup'>
                    <input
                      className='zipcode'
                      type='text'
                      maxLength='5'
                      name='zipcode'
                      id='footerzip'
                      defaultValue={form.zip}
                    />
                    <Link to={ROUTES.zipCode}>
                      <button
                        type='submit'
                        className='getquotes'
                        id='footerfinish'
                      >
                        <span>Get Quotes</span>
                        <i className='flaticon-angle-arrow-pointing-to-right'></i>
                      </button>
                    </Link>
                  </div>
                  <div className='d-flex'>
                    <hr className='my-auto flex-grow-1' />

                    <div className='px-4'>OR</div>
                    <hr className='my-auto flex-grow-1' />
                  </div>
                  <form>
                    <h3>Talk to a licensed insurance agent now:</h3>
                    <a href='tel:+18773291650' className='phonenumber'>
                      <i className='flaticon-call'></i>(877) 329-1650 TTY 711
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MedicarePlan;
