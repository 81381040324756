import React from 'react';
import Logo from '../../assets/image/main_logo.svg';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useSelector } from 'react-redux';

const Header = () => {
  const websiteConfig = useSelector((s)=> s.form?.websiteMetaData)
  return (
    <header>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12 col-md-6 col-sm-5'>
            <div className='logo'>
              <Link to={ROUTES.landing}>
                <img src={Logo} alt='' />
                <p className="footer-logo-text mt-0">{websiteConfig.websiteName}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
