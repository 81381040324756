import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/NavBar/Navbar'
import TermsAndCondition from '../components/TermsAndPrivacy/TermsAndCondition'

const TermsCondition = () => {
  return (
    <div>
        <Navbar />
        <TermsAndCondition />
        <Footer />
    </div>
  )
}

export default TermsCondition
