import React from 'react'
import SupportAgentImage from "../../assets/image/support-agent.png"

const PeopleAnswer = () => {
  return (
    <div className='section_holder'>
    <section className='section4'>
      <div className='container-xxl container'>
        <div className='row sectionsecond'>
          <div className='col-12 offset-md-1 col-md-10 offset-lg-0 col-lg-6 order-2 order-lg-1'>
            <div className='section2holder'>
              <h1>
                <span>Real </span> answers from <span>Real</span> people.
              </h1>
              <p>
                Our licensed insurance agent will answer any and all questions
                you may have , and help you find the right Medicare plan for
                your needs.
              </p>
              <ul>
                <li>
                  <div className='features'>
                    <div className='featureicon'>
                      <i className='flaticon-check'></i>
                    </div>
                    <div className='featurecontent'>
                      Learn about your standard medicare (parts A and B) and
                      about the medicare advantage plans.
                    </div>
                  </div>
                </li>
                <li>
                  <div className='features'>
                    <div className='featureicon'>
                      <i className='flaticon-check'></i>
                    </div>
                    <div className='featurecontent'>
                      Learn about the Medicare annual enrollment period, and
                      what it means to you.
                    </div>
                  </div>
                </li>
                <li>
                  <div className='features'>
                    <div className='featureicon'>
                      <i className='flaticon-check'></i>
                    </div>
                    <div className='featurecontent'>
                      Speak with a licensed insurance agent you can trust,
                      review all of your available options, and try to find
                      the right Medicare plan for you.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-12 col-lg-6 order-1  order-lg-2'>
            <div className='section2image'>
              <img
                src={SupportAgentImage}
                className='img-fluid'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}

export default PeopleAnswer