import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { moveProgress, setAddress } from "../../store/form";

const initialValues = {
  streetAddress: '',
  postalCode: '',
  homeCity: '',
  homeState: '',
};

const Address = () => {
  const dispatch = useDispatch();
  const form = useSelector(s=> s.form);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    console.log(values); // You can perform actions with the form values here
  };

  // const validationSchema = Yup.object().shape({
  //   streetAddress: Yup.string().max(4, 'Must be 4 characters or less'),
  //   postalCode: Yup.string()
  //     .max(5, 'Must be 5 characters')
  //     .required('Required'),
  //   homeCity: Yup.string().required('Required'),
  //   homeState: Yup.string().required('Required'),
  // });
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: (values) => {
      dispatch(setAddress({
        streetAddress: values.streetAddress,
        addressPostalCode: values.postalCode,
        homeCity:values.homeCity,
        homeState: values.homeState
      }))
      navigate(ROUTES.email)
      // Perform form submission logic here
    },
  });

  useEffect(() => {
    dispatch(moveProgress(50));
    formik.setValues({
      streetAddress: form.streetAddress,
      postalCode: form.addressPostalCode,
      homeCity: form.homeCity,
      homeState: form.homeState
    })
  }, []);

  return (
    <div className='form_holder'>
    <div className='container-xxl'>
      <div className='row'>
        <div className='col-12 col-md-12'>
          <div className='form_container'>
            <form  onSubmit={formik.handleSubmit}>
              <div className='steps'>
                <h3
                  id='steps-uid-0-h-0'
                  tabIndex='-1'
                  className='title current'
                >
                  Whats Is Your Address?
                </h3>
                  <section className='step-wrapper'>
                    <div className='form-step'>
                      <div className='address-first'>
                        <div className='form-group stadd'>
                          <label htmlFor='haddress1' className='control-label'>
                            Street Address (Optional)
                          </label>
                          <input
                            data-ls='streetAddress'
                            name='streetAddress'
                            id='autocomplete'
                            className='custominput'
                            placeholder='St. Address'
                            value={formik.values.streetAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete='off'
                          />
                          {/* {formik.touched.streetAddress &&
                            formik.errors.streetAddress && (
                              <div className='error error-message mt-1'>
                                {formik.errors.streetAddress}
                              </div>
                            )} */}
                        </div>
                        <div className='form-group zippost'>
                          <label htmlFor='postalcode' className='control-label'>
                            Zip/Postal code
                          </label>
                          <input
                            data-ls='postalCode'
                            id='postalCode'
                            name='postalCode'
                            className='custominput'
                            maxLength='4'
                            type='number'
                            value={formik.values.postalCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {/* {formik.touched.postalCode &&
                            formik.errors.postalCode && (
                              <div className='error error-message mt-1 ms-1'>
                                {formik.errors.postalCode}
                              </div>
                            )} */}
                        </div>
                      </div>
                      <div className='address-two'>
                        <div className='form-group '>
                          <label htmlFor='homecity' className='control-label'>
                            Home City
                          </label>
                          <input
                            data-ls='homeCity'
                            name='homeCity'
                            id='homeCity'
                            className='custominput'
                            value={formik.values.homeCity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type='text'
                          />
                          {/* {formik.touched.homeCity &&
                            formik.errors.homeCity && (
                              <div className='error error-message ms-1 mt-1'>
                                {formik.errors.homeCity}
                              </div>
                            )} */}
                        </div>
                        <div className='form-group '>
                          <label htmlFor='homestate' className='control-label'>
                            Home State
                          </label>
                          <select
                            data-ls='homestate'
                            name='homeState'
                            id='homestate'
                            className='form-control'
                            value={formik.values.homeState}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option>Select</option>
                            <option value='AL'>Alabama</option>
                            <option value='AK'>Alaska</option>
                            <option value='AZ'>Arizona</option>
                            <option value='AR'>Arkansas</option>
                            <option value='CA'>California</option>
                            <option value='CO'>Colorado</option>
                            <option value='CT'>Connecticut</option>
                            <option value='DE'>Delaware</option>
                            <option value='DC'>District of Columbia</option>
                            <option value='FL'>Florida</option>
                            <option value='GA'>Georgia</option>
                            <option value='HI'>Hawaii</option>
                            <option value='ID'>Idaho</option>
                            <option value='IL'>Illinois</option>
                            <option value='IN'>Indiana</option>
                            <option value='IA'>Iowa</option>
                            <option value='KS'>Kansas</option>
                            <option value='KY'>Kentucky</option>
                            <option value='LA'>Louisiana</option>
                            <option value='ME'>Maine</option>
                            <option value='MD'>Maryland</option>
                            <option value='MA'>Massachusetts</option>
                            <option value='MI'>Michigan</option>
                            <option value='MN'>Minnesota</option>
                            <option value='MS'>Mississippi</option>
                            <option value='MO'>Missouri</option>
                            <option value='MT'>Montana</option>
                            <option value='NE'>Nebraska</option>
                            <option value='NV'>Nevada</option>
                            <option value='NH'>New Hampshire</option>
                            <option value='NJ'>New Jersey</option>
                            <option value='NM'>New Mexico</option>
                            <option value='NY'>New York</option>
                            <option value='NC'>North Carolina</option>
                            <option value='ND'>North Dakota</option>
                            <option value='OH'>Ohio</option>
                            <option value='OK'>Oklahoma</option>
                            <option value='OR'>Oregon</option>
                            <option value='PA'>Pennsylvania</option>
                            <option value='RI'>Rhode Island</option>
                            <option value='SC'>South Carolina</option>
                            <option value='SD'>South Dakota</option>
                            <option value='TN'>Tennessee</option>
                            <option value='TX'>Texas</option>
                            <option value='UT'>Utah</option>
                            <option value='VT'>Vermont</option>
                            <option value='VA'>Virginia</option>
                            <option value='WA'>Washington</option>
                            <option value='WV'>West Virginia</option>
                            <option value='WI'>Wisconsin</option>
                            <option value='WY'>Wyoming</option>
                          </select>
                          {/* {formik.touched.role && formik.errors.role && (
                            <div className='error error-message ms-1 mt-1'>
                              {formik.errors.role}
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className='actions funnel-wrapper clearfix'>
                    <a>
                      <button className='continue-btn' onClick={handleSubmit}>
                        Continue
                      </button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
