import { configureStore, createStore } from '@reduxjs/toolkit'
import formReducer from './form'
import { loadState, saveState } from './sessionStorage';

const persistedState = loadState();

export const store = configureStore({
  reducer: {
    form: formReducer,
  },
  preloadedState: persistedState
})



store.subscribe(() => {
  saveState(store.getState());
});