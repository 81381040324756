import React, { useEffect, useState } from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import "./FormHeadLine.scss"
import { useSelector, useDispatch } from 'react-redux';
import { moveProgress } from '../../store/form';

const FormHeadLine = ({progressCounter}) => {
  const {progress, isFormFillStarted} = useSelector((s)=> ({ progress: s.form.progress, isFormFillStarted: s.form.isFormFillStarted })) 
  const dispatch = useDispatch();

  const startText = progress > 0 ? progress +  "%" : isFormFillStarted ? "0%" : "Start" 
  return (
    <div className='formheadline'>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12'>
            <h1>Find Affordable Medicare Plans in BR!</h1>
            <div className='progress-wrapper'>
              <div className='progress d-flex justify-content-center align-items-center'>
                <div
                className='progress-bar'
                >
                  <CircularProgressbar value={progress} text={`${startText}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormHeadLine;
