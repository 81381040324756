import React from "react";
import Thanks from "../components/Thanks/Thanks";
import Header from "../components/Thanks/Header";

const ThanksPage = () => {
  return (
    <div className="site_wrapper thanks_page_wrapper">
      <Header />
      <Thanks />
    </div>
  );
};

export default ThanksPage;
