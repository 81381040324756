import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { moveProgress, setMedicare, startFromFill } from "../../store/form";

const MedicarePart = ({ setProgressCounter }) => {
  const dispatch = useDispatch();
  const form = useSelector((s) => s.form);

  const navigate = useNavigate();

  const navigateToFullNamePage = (value) => {
    dispatch(setMedicare(value));
    navigate(ROUTES.fullName);
  };

  useEffect(() => {
    dispatch(moveProgress(17));
  }, []);
  return (
    <div className="form_holder">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="form_container">
              <form>
                <div className="steps">
                  <h3
                    id="steps-uid-0-h-0"
                    tabIndex="-1"
                    className="title current"
                  >
                    Do You Currently Have Medicare Part A Or B Coverage?
                  </h3>
                  <section className="step-wrapper">
                    <div className="form-step">
                      <ul className="form_option">
                        <li
                          className="option"
                          onClick={() => navigateToFullNamePage("yes")}
                        >
                          <a className={`${form.medicare === 'yes' ? 'active-option' : ''}`}>Yes</a>
                        </li>
                        <li
                          className="option"
                          onClick={() => navigateToFullNamePage("no")}
                        >
                          <a className={`${form.medicare === 'no' ? 'active-option' : ''}`}>No</a>
                        </li>
                      </ul>
                      <Link to={ROUTES.fullName}>
                        <div
                          className="unsure_btn_container"
                          onClick={() => navigateToFullNamePage(null)}
                        >
                          <button className="unsure_btn">Unsure</button>
                        </div>
                      </Link>
                    </div>
                  </section>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicarePart;
