import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

const DoNotSellPolicy = () => {
  const websiteConfig = useSelector((s) => s.form?.websiteMetaData);
  const { domainName, websiteName }  = websiteConfig;
  return (
    <section className='section1 terms_privacy_container'>
      <div className='container-xxl container'>
        <div className='row'>
          <div className='col-md-12'>
            <h1 className='py-3 bold text-center'>
              Do Not Sell My Information
            </h1>
            <hr />
            <p>
              <span className='text-underline'>ADVERTISEMENTS AND COOKIES</span>
            </p>
            <p>
              For California and Nevada residents, {domainName}
              (which does business as {websiteName} ("we" or "us" or "{websiteName}")), sharing activities may be considered a "sale" of
              personal information under applicable state law. This includes
              when we share information collected from cookies and similar
              tracking technologies (such as how you interact with our Sites)
              with our Third-Party Partners or third party service providers in
              order to create user profiles and present advertising messages
              based on your preferences and other information we have collected
              from you (to learn more see "
              <Link to={ROUTES.privacyPolicy}>
                Information Collection Technologies and Cookies
              </Link>
              ").
            </p>
            <p>
              Subject to certain exceptions, as a California or Nevada resident,
              you can change your browser options to stop accepting cookies or
              to prompt you before accepting cookies to opt-out of the
              collection and sharing of your information. Note that certain
              aspects of our service may be limited if you do not accept
              cookies. More information about cookies and other tracking
              technologies is available at allaboutcookies.org or
              youronlinechoices.eu.
            </p>
            <p>
              Some web browsers may transmit "do not track" signals. We do not
              take action to respond to do not track signals available on some
              browsers. This is because web browsers may incorporate or activate
              these features differently, making it unclear if users have
              consciously activated them. As a result, at this time we do not
              take steps to respond to such signals. As noted above, you can
              manage your cookie preferences using your own browser settings to
              accept or block some or all cookies or receive notice so you can
              consent to cookies. If you block all cookies some features of our
              Sites may be unavailable or limited.
            </p>
            <p>
              Keep in mind that, if you opt out of the collection and use of
              information via cookies and other tracking technologies, you may
              still see customized advertising displayed by advertising networks
              from which you have not opted out. In addition, your opt-out will
              not prevent any advertising network from displaying advertising
              that is not customized to you and your interests and,
              consequently, may be less relevant to you. Please also note that
              you will need to make such choices on each browser and device you
              may use to visit our Sites.
            </p>
            <p>
              <span className='text-underline'>
                INFORMATION YOU PROVIDE TO {websiteName}
              </span>
            </p>
            <p>
              For California and Nevada residents, information that you provide
              to us through our Sites, Webforms, telephone calls, mail, and
              email is shared at your direction with our Third-Party Partners
              (see our full{' '}
              <Link to={ROUTES.privacyPolicy} target='_blank'>
                Privacy Policy
              </Link>{' '}
              to learn more) which may be considered a "sale" under applicable
              state laws. If you wish to put on file with us your preference to
              opt-out of our sharing of your personal Information with third
              parties for such third parties' direct marketing purposes and
              business purposes or to find out more about your opt-out rights,
              please complete the form below or call us at 1-833-549-0351.
            </p>
            <p>
              If you wish to submit an opt-out request as a representative of a
              consumer or household, please email our Data Privacy Officer at{' '}
              <a href={`mailto:dpo@${websiteConfig.domainName}`}>dpo@{websiteConfig.domainName}</a>
              .
            </p>
            <div className='form-wrapper'>
              <form id='msform1' novalidate='novalidate'>
                <div className='form-group'>
                  <label htmlFor='' className='control-label bold me-1'>
                    Email
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    name='email'
                    value=''
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='' className='control-label bold me-1'>
                    Phone
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='phone'
                    id='phone'
                  />
                </div>
                <div className='form-group'>
                  <label htmlFor='' className='control-label bold me-1'>
                    Zip Code
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    name='zipcode'
                    id='zipccode'
                  />
                </div>
                <div className='form-group'>
                  <button className='btn mystepbtn'>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DoNotSellPolicy;
