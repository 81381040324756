import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const QuestionAnswer = () => {
  const [activeItem, setActiveItem] = useState(null);
  const websiteConfig = useSelector((s) => s.form?.websiteMetaData);

  const toggleActiveItem = (index) => {
    setActiveItem((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='section_holder'>
      <section className='faq'>
        <div className='container-xxl container'>
          <div className='row'>
            <div className='col-12 offset-lg-2 col-lg-8'>
              <div className='faqtitle'>
                <h1>We love to answer your questions</h1>
                <p>
                  We understand that Medicare insurance can be a little bit
                  confusing. That's why we have licensed insurance agents to
                  walk you through the process.
                </p>
              </div>
            </div>
            <div className='col-12  offset-lg-2 col-lg-8'>
              <div className='faqs'>
                <ul className='faq'>
                  <li
                    className={`q ${activeItem === 1 ? 'active' : ''}`}
                    value=''
                    onClick={() => toggleActiveItem(1)}
                  >
                    Why should I use {websiteConfig.domainName}?{' '}
                    <i className='flaticon-plus'></i>
                    <i className='flaticon-minus'></i>
                  </li>
                  <li className={`a ${activeItem === 1 ? 'display-list-item' : ''}`}>
                    We help you learn about your Medicare options and find the
                    right plan that fits your needs.
                  </li>
                  <li
                    className={`q ${activeItem === 2 ? 'active' : ''}`}
                    onClick={() => toggleActiveItem(2)}
                  >
                    Do I have to pay for your help?{' '}
                    <i className='flaticon-plus'></i>
                    <i className='flaticon-minus'></i>
                  </li>
                  <li className={`a ${activeItem === 2 ? 'display-list-item' : ''}`}>
                    <p>
                      No, we provide our services at no charge to you. We are
                      happy to answer your questions, help you compare your
                      plans, and decide if Medicare coverage is right for you.
                    </p>
                  </li>
                    <li
                    className={`q ${activeItem === 3 ? 'active' : ''}`}
                    onClick={() => toggleActiveItem(3)}
                    >
                    What if I don't know what plan I need?{' '}
                    <i className='flaticon-plus'></i>
                    <i className='flaticon-minus'></i>
                    </li>
                    <li className={`a ${activeItem === 3 ? 'display-list-item' : ''}`}>
                    <p>
                        Our licensed insurance agent can help you figure out your
                        needs and what's important to you in a plan and find an
                        affordable plan that gives you peace of mind knowing you
                        are covered.
                    </p>
                    </li>
                    <li
                    className={`q ${activeItem === 4 ? 'active' : ''}`}
                    onClick={() => toggleActiveItem(4)}
                    >
                    What if I already have a Medicare?{' '}
                    <i className='flaticon-plus'></i>
                    <i className='flaticon-minus'></i>
                    </li>
                    <li className={`a ${activeItem === 4 ? 'display-list-item' : ''}`}>
                    <p>
                        If you already have a Medicare plan, but want to change
                        plan, coverage, or lower your premiums, you are in the
                        right place. If you already have a Medicare plan, but
                        want to change plans, coverage or lower your premiums you
                        may do so during the Annual Election/Open Enrollment
                        Period (OEP): each year between October 15 and December
                        7. You may also qualify to switch plans at any time
                        under special circumstances that qualify you for a
                        Special Enrollment Period (SEP). Simply call us at{' '}
                        <a href='tel:+18773291650'>(877) 329-1650 TTY 711</a> or
                        fill out the form on this page.
                    </p>
                    </li>
                    <li
                    className={`q ${activeItem === 5 ? 'active' : ''}`}
                    onClick={() => toggleActiveItem(5)}
                    >
                    How do I get started ?{' '}
                    <i className='flaticon-plus'></i>
                    <i className='flaticon-minus'></i>
                    </li>
                    <li className={`a ${activeItem === 5 ? 'display-list-item' : ''}`}>
                    <p>
                        You can get started by filling out the form here or by
                        calling us directly at{' '}
                        <a href='tel:+18773291650'>(877) 329-1650 TTY 711</a>.
                        Our licensed insurance agents are available to answer
                        your questions and help you find the right plan for your
                        needs.
                    </p>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QuestionAnswer;
