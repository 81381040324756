import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const form = useSelector(s=> s.form)
  return (
    <div className="section_holder">
      <header>
        <div className="container-xxl container ">
          <div className="row">
            <div className="col-12">
              <div className="herosection">
                <div className="herosectionholder">
                  <div className="herosectionleft">
                    <h1 className="mb-3">Compare Medicare Advantage Plans</h1>
                    <h2 className="mb-2">Right Plans In BR</h2>
                    <p className="mb-3">
                      Call now to compare rates and see how much you may save.
                    </p>
                    <div className="form_holder">
                      <h3>Start By Adding Your ZIP Code</h3>
                      <form method="post">
                        <div className="form-group">
                          <input
                            data-ls="zipcode"
                            name="zipcode"
                            type="text"
                            className="form-control input-lg"
                            id="zipcode"
                            placeholder="Zipcode"
                            required=""
                            maxLength="5"
                            defaultValue={form.zip}
                          />
                          <Link to={"/form/zip-code"}>
                            <button
                              type="submit"
                              className="getquotes"
                              id="finishbutton"
                            >
                              <span>Get Quotes</span>
                              <i className="flaticon-angle-arrow-pointing-to-right"></i>
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                    <div className="tollfreeheader">
                      <h3>Talk To A Licensed Insurance Agent Now:</h3>
                      <a href="tel:+18773291650">
                        <i className="flaticon-call"></i>(877) 329-1650 TTY 711
                      </a>
                    </div>
                  </div>
                </div>
                <div className="herosectionimage"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default HeroSection;
