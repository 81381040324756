import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { moveProgress, setZipCode, setLocalZipCode, startFromFill } from "../../store/form";
import axios from "axios";

const initialValues = {
  zipCode: "",
};

const ZipCodeComponent = ({ setProgressCounter }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useSelector(s=> s.form)

  const handleSubmit = (values) => {
    console.log(values); // You can perform actions with the form values here
  };

  // const validationSchema = Yup.object().shape({
  //   zipCode: Yup.string().required("Required"),
  // });
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: (values) => {
      if (values.zipCode) {
        incZipFormState();
      } else {
        // navigate(ROUTES.medicare);
      }
    },
  });

  const incZipFormState = () => {
    // let JornayaToken = document.getElementById("leadid_token")?.value;
    // if (!JornayaToken) JornayaToken = "";

    axios
      .get("https://api.zippopotam.us/us/" + formik.values.zipCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      })
      .then((response) => {
        const obj = response.data;
        dispatch(setLocalZipCode(false))
        dispatch(
          setZipCode({
            zip: formik.values.zipCode,
            city: response.data["places"][0]["place name"],
            state: response.data["places"][0]["state abbreviation"],
          })
        );

        navigate(ROUTES.medicare);
      })
      .catch((error) => {
        // alert(JSON.string(error))
        navigate(ROUTES.medicare);
      });
  };

  useEffect(() => {
    dispatch(moveProgress(0));
    dispatch(startFromFill())
    if(!form.zipCodeSetByMaxMind) {
      formik.setValues({ zipCode: form.zip })
    }
  }, []);
  return (
    <div className="form_holder">
      <div className="container-xxl">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="form_container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
              >
                <div className="steps">
                  <h3
                    id="steps-uid-0-h-0"
                    tabIndex="-1"
                    className="title current"
                  >
                    Whats your zipcode?
                  </h3>
                  <section className="step-wrapper">
                    <div className="form-step">
                      <div className="input-group">
                        <input
                          type="text"
                          className="custominput"
                          placeholder="ZipCode"
                          id="zipCode"
                          maxLength={5}
                          value={formik.values.zipCode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.zipCode && formik.errors.zipCode && (
                          <div className="error error-message mt-1 ms-1">
                            {formik.errors.zipCode}
                          </div>
                        )} */}
                      </div>
                    </div>
                  </section>
                  <div className="actions clearfix">
                    <Link to={ROUTES.medicare}> 
                      <button className="continue-btn">Continue</button>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ZipCodeComponent;
