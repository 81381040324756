import React, { useEffect } from "react";
import Navbar from "../components/NavBar/Navbar";
import HeroSection from "../components/HeroSection/HeroSection";
import HowItWorks from "../components/HowItWOrks/HowItWorks";
import InsuranceSection from "../components/InsurenceSection/InsuranceSection";
import QuestionAnswer from "../components/QuestionAnswersection/QuestionAnswer";
import PeopleAnswer from "../components/PeopleAnswer/PeopleAnswer";
import MedicarePlan from "../components/MediCarePlan/MedicarePlan";
import Footer from "../components/Footer/Footer";
import { useDispatch } from "react-redux";
import { setZipCode } from "../store/form";
import { ROUTES } from "../constants/routes";
import { Helmet } from "react-helmet-async";
import { LEAD } from "../constants/lead";

const Home = () => {
  const dispatch = useDispatch();

  const cityAddress = async () => {
    const options = {};
    const onSuccess = (success) => {
      const country = success.country
        ? success.country.names
          ? success.country.names.en
          : ""
        : "";
      const city = success.city
        ? success.city.names
          ? success.city.names.en
          : ""
        : "";
      const state = success.subdivisions
        ? success.subdivisions[0]
          ? success.subdivisions[0].names.en
          : ""
        : "";

      const zip = success.postal.code;
      dispatch(setZipCode({ state, city, zip }));
    };
    const onError = (error) => {
      console.log(error);
    };
    if (window.geoip2) {
      try {
        await window.geoip2.city(onSuccess, onError, options);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    cityAddress();
  }, []);
  return (
    <div>
      {window.location.pathname !== ROUTES.thanks ||
      window.location.pathname !== ROUTES.congratulations ? (
        <LeadNode />
      ) : undefined}

      {!window.location.pathname.includes("congrats") &&
      !window.location.pathname.includes(ROUTES.thanks) &&
      !window.location.pathname.includes(ROUTES.congratulations) ? (
        <input id="leadid_token" name="universal_leadid" type="hidden" />
      ) : undefined}

      {!window.location.pathname.includes("congrats") &&
      !window.location.pathname.includes(ROUTES.thanks) &&
      !window.location.pathname.includes(ROUTES.congratulations) ? (
        <LeadNoScript />
      ) : undefined}

      <Navbar />
      <HeroSection />
      <HowItWorks />
      <InsuranceSection />
      <PeopleAnswer />
      <QuestionAnswer />
      <MedicarePlan />
      <Footer />
      <div className="section_holder">
        <div className="d-block d-sm-block d-md-none d-lg-none">
          <a href="tel:+18773291650" className="callbutton">
            <i className="flaticon-call"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

function LeadNode() {
  return (
    <Helmet>
      <script
        id={LEAD.id}
        type={LEAD.type}
        async={LEAD.async}
        src={LEAD.src}
      ></script>
      <noscript> Fail to load javascript </noscript>
    </Helmet>
  );
}

function LeadNoScript() {
  return (
    <Helmet>
      <noscript>
        {
          '<img src="//create.leadid.com/noscript.gif?lac=E1DEE81A-527B-1485-290C-8AAFFDB8B593&lck=e4127813-533f-79a4-c481-7daca8b33bed&snippet_version=2" />'
        }
      </noscript>
    </Helmet>
  );
}

export default Home;
