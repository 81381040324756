import React from 'react'
import Navbar from '../components/NavBar/Navbar'
import Footer from '../components/Footer/Footer'
import DoNotSellPolicy from '../components/TermsAndPrivacy/DoNotSellPolicy'

const DoNotSellMyPolicy = () => {
  return (
    <div>
      <Navbar />
      <DoNotSellPolicy />
      <Footer />
    </div>
  )
}

export default DoNotSellMyPolicy
