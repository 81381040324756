import React from "react";
import Logo from "../../assets/image/main_logo.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useSelector } from "react-redux";

const Navbar = () => {
  const websiteConfig = useSelector((s) => s.form?.websiteMetaData);
  return (
    <div className="section_holder">
      <div className="navbar">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 col-sm-5">
              <div className="logo">
                <a href="#">
                  <img src={Logo} alt="" />
                  <p className="logo-text">{websiteConfig.websiteName}</p>
                </a>
              </div>
            </div>
            <div className="d-none d-sm-block col-12 col-md-6 col-sm-7">
              <div className="tollfree">
                <a className="agent" href="tel:+18773291650">
                  <div className="tollfree-flex">
                    <div className="tollfree-flex1">
                      <div className="tollfreetext">
                        <p>Speak to a Licensed Insurance Agent</p>
                      </div>
                      <div className="tollfreenumber">
                        <i className="flaticon-call"></i>(877) 329-1650 TTY 711
                      </div>
                      <div className="tollfreetext2">
                        <span></span> CALL TOLL-FREE M-F 7AM-10PM CST
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
